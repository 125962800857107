import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  siteUrl,
  author,
  title,
  image,
  lang,
  meta,
  keywords,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaSiteUrl = siteUrl || site.siteMetadata.siteUrl
  const metaAuthor = author || site.siteMetadata.author
  const metaImage = image || site.siteMetadata.image
  const metaKeywords =
    keywords ||
    "syawaljasira, syawal jasira, Syawal Jasira, syawal blog, syawal jasira personal website, syawal portfolio, syawal 2019"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "google-site-verification",
          content: "eMK2QetN7HEN7UnPF-FHFKzvxHtLAtKw3xhy74Nb5cc",
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaSiteUrl,
        },
        {
          property: `og:author`,
          content: metaAuthor,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="shortcut icon"
        href="https://www.syawaljasira.com/favicon-32x32.png?v=51640313e4d671ff1fefa53c13fac19d"
        type="image/png"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"
        rel="stylesheet"
      />
      <link rel="canonical" href={metaSiteUrl} />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en-US`,
  meta: [],
  description: ``,
  siteUrl: ``,
  author: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
