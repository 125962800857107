import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

export default function Footer({ children }) {
  const { footerImage } = useStaticQuery(graphql`
    query BgFooterQuery {
      footerImage: file(relativePath: { eq: "bg-footers.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1440, quality: 100)
        }
      }
    }
  `)

  const image = getImage(footerImage)

  return (
    <BgImage image={image} style={{ backgroundSize: "cover", minHeight: 64 }}>
      <footer className="font-sans pt-5 md:py-7 lg:py-10 text-center items-center text-lg md:text-xl xl:text-2xl text-white">
        <small>{children}</small>
      </footer>
    </BgImage>
  )
}
