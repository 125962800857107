import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FiMenu } from "@react-icons/all-files/fi/FiMenu"
import { FiX } from "@react-icons/all-files/fi/FiX"
import classNames from "classnames"

const Header = ({ siteTitle, classNameNav }) => {
  const [offcanvas, setOffcanvas] = React.useState(false)
  const [navbar, setNavbar] = React.useState(false)

  const isBrowser = typeof window !== "undefined"

  React.useEffect(() => {
    const changeBgNavbar = () => {
      if (isBrowser) {
        if (window.scrollY >= 5) {
          setNavbar(true)
        } else {
          setNavbar(false)
        }
      }
    }

    window.addEventListener("scroll", changeBgNavbar)

    return () => {
      window.removeEventListener("scroll", changeBgNavbar)
    }
  }, [])

  return (
    <section className="fixed w-full z-10">
      <div
        id="navbar"
        className={classNames(
          "font-sans p-5 flex items-center transition-all duration-200",
          navbar
            ? "bg-light shadow-md text-green-800"
            : classNameNav
            ? classNameNav
            : "text-white"
        )}
      >
        <nav
          id="navbar-brand"
          className="text-sm md:text-lg lg:text-xl w-8/12 md:w-6/12 md:mx-auto px-5"
        >
          <Link
            className={classNames(
              "px-2 hover:py-5 duration-300",
              navbar ? "hover:bg-gray-200" : "hover:bg-green-850"
            )}
            to="/"
          >
            {siteTitle}
          </Link>
        </nav>
        <div id="nav-link" className="hidden md:flex md:mx-auto lg:text-lg">
          <nav
            className={classNames(
              "nav-item px-4 lg:px-6 duration-300",
              navbar ? "hover:bg-gray-200" : "hover:bg-green-850"
            )}
          >
            <Link to="/">Home</Link>
          </nav>
          <nav
            className={classNames(
              "nav-item px-4 lg:px-6 duration-300",
              navbar ? "hover:bg-gray-200" : "hover:bg-green-850"
            )}
          >
            <Link to="/blog">Blog</Link>
          </nav>
          <nav
            className={classNames(
              "nav-item px-4 lg:px-6 duration-300",
              navbar ? "hover:bg-gray-200" : "hover:bg-green-850"
            )}
          >
            <Link to="/resume">Resume</Link>
          </nav>
          <nav
            className={classNames(
              "nav-item px-4 lg:px-6 duration-300",
              navbar ? "hover:bg-gray-200" : "hover:bg-green-850"
            )}
          >
            <Link to="/contact">Contact</Link>
          </nav>
        </div>
        <div className="w-4/12 block md:hidden">
          <div
            onClick={() => setOffcanvas(true)}
            className={classNames(
              "text-2xl flex justify-end px-5 duration-300",
              navbar ? "text-green-800" : "text-white"
            )}
          >
            <FiMenu
              className={classNames(
                navbar ? "hover:bg-gray-200" : "hover:bg-green-850"
              )}
            />
          </div>
        </div>
      </div>

      <div
        className={classNames(
          "fixed flex-col z-20 h-full w-full top-0 text-white bg-green-700 md:hidden transition-all duration-500",
          offcanvas ? "right-0" : "-right-full"
        )}
      >
        <div className="text-2xl w-full pt-8 pr-8 flex justify-end text-right duration-300">
          <span onClick={() => setOffcanvas(false)}>
            <FiX />
          </span>
        </div>
        <nav className="text-lg w-full mt-24 py-3 text-center hover:bg-green-850 duration-300">
          <Link to="/">Home</Link>
        </nav>
        <nav className="text-lg w-full py-3 text-center hover:bg-green-850 duration-300">
          <Link to="/blog">Blog</Link>
        </nav>
        <nav className="text-lg w-full py-3 text-center hover:bg-green-850 duration-300">
          <Link to="/resume">Resume</Link>
        </nav>
        <nav className="text-lg w-full py-3 text-center hover:bg-green-850 duration-300">
          <Link to="/contact">Contact</Link>
        </nav>
      </div>
    </section>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
